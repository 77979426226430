/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Tile {
  font-size: 2.5em;
  text-align: left;
  width: 260px;
  height: 100px;
  display: inline-block;
  position: relative;
}

.Tile-label {
  position: absolute;
  top: 24px;
  left: 70px;
  right: 0;
  font-size: 0.4em;
  text-transform: uppercase;
}

.Tile-content {
  position: absolute;
  bottom: 16px;
  left: 70px;
  right: 0;
  font-weight: 300;
  font-size: 0.75em;
}

.Tile .Icon {
  position: absolute;
  left: 20px;
  top: 15px;
  font-size: 0.8em;
  padding: 0.1em;
  border-radius: 1.25em;
  border: 2px solid #e6007a;
  color: #e6007a;
}
