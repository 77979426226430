/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.List {
  /* Prevents the list from auto-scrolling while cascading node
   * updates on new block, which helps with performance. */
  overflow-anchor: none;
}

.List-no-nodes {
  font-size: 30px;
  padding-top: 20vh;
  text-align: center;
  font-weight: 300;
}

.List-padding {
  padding: 0;
  margin: 0;
}

.List--table {
  width: 100%;
  border-spacing: 0;
  font-family: monospace, sans-serif;
}
