/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Chain-Tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 18px;
  line-height: 20px;
  height: 32px;
  width: 32px;
  color: #555;
  cursor: pointer;
  border-radius: 40px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.Chain-Tab:hover {
  background: #ccc;
}

.Chain-Tab-on,
.Chain-Tab-on:hover {
  background: #e6007a;
  color: #fff;
}
