/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.App {
  text-align: left;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  min-width: 1318px;
}

.App-no-telemetry {
  width: 100vw;
  height: 100vh;
  line-height: 80vh;
  font-size: 56px;
  font-weight: 100;
  text-align: center;
  color: #888;
}
