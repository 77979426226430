/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Filter {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  left: 50%;
  width: 400px;
  font-size: 30px;
  margin-left: -210px;
  padding: 10px 10px 10px 60px;
  border-radius: 4px;
  background: #111;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.Filter-hidden {
  bottom: -300px;
}

.Filter input {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  width: 350px;
  font-size: 30px;
  background: #111;
  color: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.Filter .Icon {
  position: absolute;
  left: 13px;
  top: 17px;
  font-size: 30px;
}
