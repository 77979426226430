/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Chains {
  background: #e6007a;
  color: #000;
  padding: 0 76px 0 16px;
  height: 40px;
  min-width: 1350px;
  position: relative;
}

.Chains-extra-subscribed-chain {
  position: absolute;
  right: 80px;
  top: 0px;
  background: #e6007a;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0px,
    #e6007a 30px,
    #e6007a 100%
  );
  z-index: 1;
  padding-left: 34px;
}

.Chains-chain {
  top: 4px;
  padding: 0 12px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  height: 36px;
  cursor: pointer;
  font-size: 0.8em;
  position: relative;
  z-index: 0;
  border-radius: 4px 4px 0 0;
}

.Chains-all-chains {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 48px;
  top: 6px;
}

.Chains-fork-me {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 12px;
  top: 6px;
}

.Chains-all-chains .Icon,
.Chains-fork-me .Icon {
  font-size: 28px;
  margin: 0;
  height: 28px;
  width: 28px;
  color: #fff;
}

.Chains-node-count {
  padding: 0px;
  display: inline-block;
  border-radius: 20px;
  background: #fff;
  color: #e6007a;
  font-size: 0.9em;
  margin-left: 0.5em;
  padding: 0.3em 0.5em;
}

/* Create a "tab background" that will rise up on hover/selection */
.Chains-chain::before {
  content: '';
  background-color: white;
  border-radius: 4px 4px 0 0;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0px;
  /*
  To animate the tab height changes, we can uncomment this line:

  transition: height ease-in-out 0.2s;
  */
}

/* Animate the tab background to rise up slightly on hover */
.Chains-chain:hover::before {
  height: 4px;
}

.Chains-chain.Chains-chain-selected {
  color: #393838;
  /*
  Instead of making the font bold, which changes the container width and
  causes some wobbling, apply a tiny text shadow to "bold" it without the
  width change:
  */
  text-shadow: -0.06ex 0 #393838, 0.06ex 0 #393838;
}

/* Animate the tab background to rise up all the way on selection */
.Chains-chain.Chains-chain-selected::before {
  height: 36px;
}

.Chains-chain.Chains-chain-selected .Chains-node-count {
  background: #393838;
  color: #fff;
}
