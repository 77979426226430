/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2022 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Stats {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 0.1rem;
}

.Stats-category {
  text-align: left;
  background-color: #fff;
  margin-bottom: 2.5rem;
  padding: 1rem;
}

.Stats-category table {
  color: #000;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.Stats-category tr:nth-child(even) {
  background-color: #eee;
}

.Stats-percent {
  width: 6em;
  text-align: right;
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.Stats-count {
  width: 6.5em;
  text-align: right;
  padding-right: 1.5rem;
  border-right: 1px solid black;
}

.Stats-value {
  padding-left: 2rem;
}

th.Stats-value {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Stats-category td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Stats-unknown {
  opacity: 0.5;
}
