/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Settings {
  text-align: center;
}

.Settings-category {
  text-align: left;
  width: 500px;
  margin: 0 auto;
  padding: 2em 0;
}

.Settings-category h2 {
  padding: 0;
  margin: 0 0 0.5em 0;
  font-size: 20px;
  font-weight: 100;
}
