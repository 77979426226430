/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.AllChains-overlay {
  position: fixed;
  z-index: 19;
  background: rgba(0, 0, 0, 0.35);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AllChains-content {
  max-height: calc(100vh - 2em);
  max-width: calc(100vw - 2em);
  border-radius: 4px;
  width: 600px;
  overflow: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.AllChains-controls {
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.AllChains-controls input {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 0.5em;
  flex-grow: 1;
  min-width: 100px;
}

.AllChains-controls-sortby {
  padding: 0.4em 0.5em;
  margin-left: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  font-size: 0.9em;
  border: 1px solid black;
}

.AllChains-controls-sortby-active {
  background-color: #e6007a;
  border-color: #e6007a;
  color: white;
}

.AllChains-chains {
  flex-grow: 1;
  overflow: auto;
  padding: 0.5em;
}

.AllChains-chain {
  padding: 10px 10px;
  background: rgb(220, 220, 220);
  color: black;
  display: inline-flex;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  position: relative;
  align-items: center;
  justify-content: center;
}

.AllChains-chain-highlighted-text {
  background-color: yellow;
  color: black;
}

.AllChains-node-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  background: #8c8787;
  color: #fff;
  font-weight: normal;
  font-size: 0.9em;
  margin-left: 0.5em;
  padding: 0.3em 0.5em;
}

.AllChains-chain-selected {
  background: #e6007a;
  color: white;
}

.AllChains-chain-selected .AllChains-node-count {
  background: white;
  color: #e6007a;
}
