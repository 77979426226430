/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.OfflineIndicator {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
  background: #c00;
  line-height: 16px;
  color: #fff;
  font-size: 30px;
  padding: 16px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 3px 20px;
}

.OfflineIndicator-upgrade {
  background: #282;
}
