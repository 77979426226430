/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Setting {
  color: #635f5f;
  padding: 0;
  margin: 0 0 8px 0;
  cursor: pointer;
}

.Setting-on {
  color: #fff;
}

.Setting .Icon {
  margin-right: 10px;
}

.Setting-switch {
  width: 40px;
  height: 18px;
  border-radius: 18px;
  display: block;
  float: right;
  position: relative;
  background: #444;
  transition: background-color 0.15s linear, border-color 0.15s linear;
}

.Setting-on .Setting-switch {
  background: #e6007a;
  border-color: #e6007a;
}

.Setting-knob {
  width: 18px;
  height: 18px;
  border-radius: 19px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  transition: left 0.15s ease-in-out;
}

.Setting-on .Setting-knob {
  left: 22px;
}
