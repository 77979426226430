/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Column {
  text-align: left;
  padding: 6px 13px;
  height: 19px;
  position: relative;
  white-space: nowrap;
}

.Column-truncate {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 6px 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Column-Tooltip {
  position: initial !important;
  padding: inherit !important;
}

.Column-validator {
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.Column-validator:hover {
  transform: scale(2);
}

.Column--a {
  color: inherit;
  text-decoration: none;
}

.Column--a:hover {
  text-decoration: underline;
}
