/*
Source code for the Substrate Telemetry Server.
Copyright (C) 2021 Parity Technologies (UK) Ltd.

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

.Location {
  width: 6px;
  height: 6px;
  background: transparent;
  border: 2px solid #666;
  border-radius: 6px;
  margin-left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 2;
  transition: border-color 0.25s linear;
}

.Location-dimmed {
  width: 2px;
  height: 2px;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 1;
  background: #bbb;
  border: none;
}

.Location-ping {
  pointer-events: none;
  position: absolute;
  display: none;
}

.Location-odd {
  border-color: #bbb;
}

.Location-synced {
  z-index: 3;
  border-color: #e6007a;
}

.Location-synced .Location-ping {
  border: 1px solid #fff;
  border-radius: 30px;
  display: block;
  animation: ping 1s forwards;
}

.Location:hover {
  z-index: 4;
  border-color: #fff;
}

.Location-details {
  min-width: 335px;
  position: absolute;
  font-family: monospace, sans-serif;
  background: #222;
  color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
}

.Location-quarter0 .Location-details {
  left: 16px;
  top: -4px;
}

.Location-quarter1 .Location-details {
  right: 16px;
  top: -4px;
}

.Location-quarter2 .Location-details {
  left: 16px;
  bottom: -4px;
}

.Location-quarter3 .Location-details {
  right: 16px;
  bottom: -4px;
}

.Location-details td {
  text-align: left;
  padding: 0.5em 1em;
}

.Location-details td:nth-child(odd) {
  width: 16px;
  text-align: center;
  padding-right: 0.2em;
}

.Location-details td:nth-child(even) {
  padding-left: 0.2em;
}

@keyframes ping {
  from {
    left: -1px;
    top: -1px;
    width: 6px;
    height: 6px;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 1);
  }

  to {
    left: -18px;
    top: -18px;
    width: 40px;
    height: 40px;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0);
  }
}

.Location-validator {
  display: inline-block;
  width: 16px;
  height: 16px;
  transform: scale(1.5);
  transform-origin: right 50%;
  margin-left: 16px;
}
